
















































































































































































































































































































































































.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
// .removeStudentLogList {
//   .searchbox {
//     .searchboxItem {
//       .itemLabel {
//         min-width: 14rem;
//       }
//     }
//   }
// }
